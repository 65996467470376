import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fshared%2Fcomponents%2FAddressAutocomplete%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA71WXW%2FbIBR9z69AqiZt0hwFO45T%2BjJFXaX9gj1OxJCY1QYEuHE65b9PgB0bO8nWqVrykBjuuR%2BHc6%2BZ%2F4AreIQHsgC%2FZgBIoZlhgiOgaIkNe6EPMwBeI8YJbRCAi4fZaTbvMNBhCNOyxEcEuOA02I%2FdPi7ZnkfM0EojoI2iJi8ehrhdSRu7YH8jwhTNfQq5KOuK2509lgjAlXRmFVZ7xhFYAFwbEQRMbhRxYMQUtoTFhwCzdJiS7gwCax%2Bhd4C3WpS1cQ6MkAgsrcUAnTr0FufPeyVqTqJclEIhcPfkPha3FYpQhQCUDdCiZATcbZJNson7zUhhwmrduvdMIADdX8FNtMMVK48IfOOGqvOqZq8UAdgmXVC2LwwCaetDYkIY3yMQx7KxlXXOrxGRop3Ia%2F1PBcFH%2BAg3dlPUpmScXlBDOteFOHzHijO%2B91FaR3Hv6Gu6WqarALeaqiinHRETETmpLEdK8Q9N1HHkFoTEOTPH7umFql0pDggUjBDqZGcU5p0SvC8wT%2FTnDukfer%2F2Ocx8UnGX0VI206wSWgV5wcBZdouG%2FrzQ8DAuq2t6ohMWu5RWLeRnrQ3bHaNccEO5GYY%2B6wzeywYksQcY2pjIJTu0HRI6lhiYQ%2B3kWTBDIy1x7kR0UFiGPCCc256%2BptM0TuN0RB0q7PFeQ2RJlmRxgFi%2Fb1uvQuL7A%2FJjZ9GLNXJTZh20sF%2BLb86mAd3W53lepX9o%2BntXaF4rbTOTggUTZjJ3%2BsO2Pesn8tDbbaKfUvsNEDh8hWxLkT%2Bfwx9aFW5FSQYUbYUxoprO4q2vZULyFe%2Ft%2BBx7yf07pCvUDk5f7AVlK5tfgCZhPYzbYRidA3d9NYlK%2F2d3uxetLhTjz6343tDv79HD9O09TP%2B2h79UlDAMdK4o5QBzAj5WjEfnBoiXsvnk3IzuMSP6PUkGK9dMF1gcXUumFxO%2F1nShs8Wis7x8QzkNc4rbnEa3q5FV2lqNz2%2F07gswWYvpezmzgzvzyMB03Zq6UeJnx8iCthZtjV380%2Bz0G85olwRaCgAA%22%7D"
export var autoComplete = '_161y1wd0';
export var formButton = '_161y1wd7';
export var formButtonAbTestCTA = '_161y1wde';
export var formDesktop = '_161y1wd1';
export var formInput = '_161y1wd5';
export var formInputWrapper = '_161y1wd3';
export var formLabel = '_161y1wd4';
export var formMobile = '_161y1wd2';
export var formWarning = '_161y1wd6';
export var suggestionsGoogleLogo = '_161y1wdd';
export var suggestionsGoogleLogoWrapper = '_161y1wdc';
export var suggestionsItem = '_161y1wd9';
export var suggestionsItemCity = '_161y1wdb';
export var suggestionsItemStreet = '_161y1wda';
export var suggestionsList = '_161y1wd8';